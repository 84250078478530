import { PropsWithChildren, ReactNode, forwardRef, ComponentPropsWithoutRef } from 'react'
import { DialogProps } from '@radix-ui/react-dialog'

import { Dialog, DialogCloseButtonClassName, DialogContent, DialogTitle, DialogTrigger } from '@/components/ui/dialog'
import { cls, preventDefault, whisper } from '@/utils'

interface DialogContentProps extends ComponentPropsWithoutRef<typeof DialogContent> {
  className?: string
}

const ModalContent = forwardRef<HTMLDivElement, PropsWithChildren<DialogContentProps>>(
  ({ className, closeClassName, overlayClassName, children, ...props }, ref) => {
    return (
      <DialogContent
        ref={ref}
        className={cls(
          'md:min-h-[480px] max-w-[1728px] md:max-w-[1728px] md:min-w-[640px] p-0 md:py-10 md:px-[100px] mx-auto size-full border-none bg-surfacemd:bg-transparent shadow-none',
          className,
        )}
        overlayClassName={overlayClassName}
        closeClassName={cls(
          'right-2 md:right-10 top-2 md:top-10 w-8 h-9 md:size-10 border-2 border-b-4 border-solid md:border-border border-border hover:border-border-hover active:border-border-hover text-icon',
          closeClassName,
        )}
        {...props}
      >
        {children}
      </DialogContent>
    )
  },
)

ModalContent.displayName = 'ModalContent'

interface ModalProps extends DialogProps {
  id?: string
  className?: string
  closeClassName?: string
  overlayClassName?: string
  withBroadcasts?: boolean
  trigger?: ReactNode
  preventFocus?: boolean
  title?: ReactNode
  okText?: ReactNode
  cancelText?: ReactNode
  footer?: ReactNode
}

export function Modal({
  id,
  trigger,
  children,
  preventFocus,
  title,
  className,
  closeClassName,
  overlayClassName,
  withBroadcasts,
  ...props
}: PropsWithChildren<ModalProps>) {
  return (
    <Dialog {...props}>
      {title ? <DialogTitle>{title}</DialogTitle> : null}
      {trigger ? <DialogTrigger asChild>{trigger}</DialogTrigger> : null}
      <ModalContent
        id={id}
        className={cls('haiper dark:haiper-invert', className)}
        overlayClassName={overlayClassName}
        closeClassName={closeClassName}
        withBroadcasts={withBroadcasts}
        onInteractOutside={preventDefault}
        onOpenAutoFocus={(e) => {
          if (!preventFocus) return
          e?.preventDefault()
          if (!e.target) return
          const elSelf = e.target as HTMLElement
          const elClose = elSelf.querySelector(`.${DialogCloseButtonClassName}`) as HTMLButtonElement
          elClose?.focus?.()
        }}
      >
        {children}
      </ModalContent>
    </Dialog>
  )
}
