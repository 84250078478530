'use client'

import { cls } from '@/utils'
import { Toaster } from '../ui/toaster'

export interface ToasterProps {
  className?: string
}

export default function HaToaster({ className }: ToasterProps) {
  return (
    <div className={cls('', className)}>
      <Toaster />
    </div>
  )
}
