import { FC, ReactNode, useCallback, useMemo, useState } from 'react'
import IconInfo from '@haiper/icons-svg/icons/outline/info-circle.svg'
import BookOpenOutline from '@haiper/icons-svg/icons/outline/book-open.svg'
import Link from '@/components/link'
import IconPrivacy from '@haiper/icons-svg/icons/outline/lock.svg'
import IconAgreement from '@haiper/icons-svg/icons/outline/document-2.svg'
import IconLogout from '@haiper/icons-svg/icons/outline/logout.svg'
import QuestonmarkOutline from '@haiper/icons-svg/icons/outline/questionmark.svg'
import { LINKS, NO_OUTLINE_STYLE } from '@/constants'
import useAmplitude from '@/hooks/useAmplitude'
import ThemeToggle from '@/components/theme-toggle'
import { cls } from '@/utils'
import IconCareer from '@haiper/icons-svg/icons/outline/bag.svg'
import IconContact from '@haiper/icons-svg/icons/outline/bubble-annotation.svg'
import { useAtomValue } from 'jotai'
import { auth0SignInAtom } from '@/atoms'
import useFooterLinks from '@/hooks/useFooterLinks'
import { NavLink } from '@/types'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuPortal,
  DropdownMenuItem,
  DropdownMenuGroup,
} from '@/components/ui/dropdown-menu'

interface ButtonMoreProps {
  children?: ReactNode
  withFooterLinks?: boolean
}

interface MoreLink {
  href: string
  icon: FC<any>
  label: string
  event: string
  target?: string
}

export function ButtonMore({ children, withFooterLinks }: ButtonMoreProps) {
  const { track } = useAmplitude()
  const [open, setOpen] = useState(false)
  const iconClassName = 'size-6 text-icon'
  const isSignedIn = useAtomValue(auth0SignInAtom)
  const { data: footerLinks } = useFooterLinks()

  const linkStyle = cls(
    'relative flex items-center gap-2 px-2 py-3 cursor-pointer m-[4px] rounded-lg bg-surface hover:bg-surface-hover active:bg-surface-hover',
    NO_OUTLINE_STYLE,
  )

  const baseLinks: MoreLink[] = useMemo(() => {
    return [
      {
        href: LINKS.about,
        icon: IconInfo,
        label: 'About',
        event: 'click:nav:about',
        target: '_blank',
      },
      {
        href: LINKS.careers,
        icon: IconCareer,
        label: 'Careers',
        event: 'click:nav:careers',
        target: '_blank',
      },
      {
        href: LINKS.contact,
        icon: IconContact,
        label: 'Contact',
        event: 'click:nav:contact',
        target: '_blank',
      },
      {
        href: LINKS.manual,
        icon: BookOpenOutline,
        label: 'Get Started',
        event: 'click:nav:manual',
        target: '_blank',
      },
      {
        href: LINKS.faq,
        icon: QuestonmarkOutline,
        label: 'FAQ',
        event: 'click:nav:faq',
        target: '_blank',
      },
      {
        href: LINKS.privacy,
        icon: IconPrivacy,
        label: 'Privacy Policy',
        event: 'click:nav:privacy',
        target: '_blank',
      },
      {
        href: LINKS.termsOfUse,
        icon: IconAgreement,
        label: 'Terms and Conditions',
        event: 'click:nav:terms',
        target: '_blank',
      },
    ]
  }, [])

  const moreLinks: NavLink[] = useMemo(() => {
    return [
      ...baseLinks,
      ...(isSignedIn
        ? [
            {
              href: '/auth/signout',
              icon: IconLogout,
              label: 'Logout',
              event: 'click:nav:signout',
            },
          ]
        : []),
    ]
  }, [baseLinks, isSignedIn])

  const close = useCallback(() => {
    setOpen(false)
  }, [])

  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger asChild>{children as any}</DropdownMenuTrigger>
      <DropdownMenuPortal>
        <DropdownMenuContent
          className={cls('w-[226px] p-0 rounded-lg backdrop-blur-xl bg-surface text-text max-h-[80vh] overflow-y-auto')}
          align='start'
        >
          <DropdownMenuGroup>
            {withFooterLinks
              ? footerLinks.map(({ href, icon: Icon, label, key, target, badge }) => (
                <DropdownMenuItem key={key} asChild>
                  <Link key={key} className={linkStyle} href={href} target={target} rel='noopener' onClick={close}>
                    <Icon className={iconClassName} />
                    <span className='text-body-md'>{label}</span>
                    {badge ?? null}
                  </Link>
                </DropdownMenuItem>
                ))
              : null}
            {moreLinks.map(({ href, icon: Icon, label, event, target }) => (
              <DropdownMenuItem key={[href, target].join('')} asChild>
                <Link
                  key={[href, target].join('')}
                  className={linkStyle}
                  href={href}
                  target={target}
                  rel='noopener'
                  onClick={() => {
                    if (event) {
                      track(event)
                    }
                    close()
                  }}
                >
                  <Icon className={iconClassName} />
                  <span className='text-body-md'>{label}</span>
                </Link>
              </DropdownMenuItem>
            ))}
          </DropdownMenuGroup>
          <div className='px-2 py-3 md:hidden flex justify-start items-center'>
            <ThemeToggle className='flex md:hidden' />
          </div>
        </DropdownMenuContent>
      </DropdownMenuPortal>
    </DropdownMenu>
  )
}
