import { PoNVoid } from '@/types'
import dayjs, { Dayjs } from 'dayjs'
import { useCallback, useEffect, useRef, useState } from 'react'

// update state when alarmed
export default function useAlarms(
  stops: Array<Dayjs | Date | string | number | string | undefined>,
  onAlarm?: (stop: Dayjs) => PoNVoid,
) {
  const getStates = useCallback(() => {
    const now = dayjs()
    return stops.map((stop) => {
      const time = dayjs(stop)
      return time.isBefore(now) || time.isSame(now)
    })
  }, [stops])

  const [alarmStates, setAlarmStates] = useState(getStates)

  const timersRef = useRef<Array<NodeJS.Timeout>>([])
  const shedule = useCallback(() => {
    // clear old timer
    timersRef.current?.forEach((timer) => clearTimeout(timer))

    const now = dayjs()
    stops.forEach((stop) => {
      const time = dayjs(stop)
      const waitTime = time.diff(now)
      if (waitTime > 0 && waitTime < 1000 * 60 * 60 * 24) {
        // 2454285018
        const timer = setTimeout(() => {
          setAlarmStates(getStates())
          onAlarm?.(time)
          shedule()
        }, waitTime)
        timersRef.current.push(timer)
      }
    })
  }, [stops, getStates, onAlarm])

  useEffect(() => {
    shedule()
  }, [stops, shedule])

  return alarmStates
}
