import { cls } from '@/utils'
import Link, { LinkProps } from '@/components/link'
import { ReactElement, forwardRef } from 'react'
import { NO_OUTLINE_STYLE } from '@/constants'
import Image from '../image'

export interface IconLinkProps extends LinkProps {
  id?: string
  className?: string
  icon?: string | React.ComponentType<any>
  badge?: ReactElement
  iconClassName?: string
  labelClassName?: string
  children?: React.ReactNode
  active?: boolean
  target?: string
  title?: string | ReactElement
}

const IconLink = forwardRef<HTMLAnchorElement, IconLinkProps>(
  ({ className, icon: Icon, active, title, children, badge, iconClassName, labelClassName, ...props }, ref) => {
    return (
      <Link
        ref={ref}
        {...props}
        className={cls(
          'relative hover:no-underline active:no-underline focus:ring-0 focus-visible:ring-0',
          NO_OUTLINE_STYLE,
          'hover:bg-surface-hover rounded-lg max-h-full p-0.5',
          className,
        )}
      >
        <div
          className={cls(
            'w-full flex items-center justify-center widescreen:justify-start p-0 md:px-3 md:py-2 @container rounded-[10px] aspect-square widescreen:aspect-auto max-h-full border-2 border-solid',
            active ? 'border-border px-[10px] py-[6px]' : 'border-transparent',
            className,
            'size-auto',
          )}
        >
          <div className='flex gap-0 widescreen:gap-4 items-center h-6'>
            {Icon ? (
              typeof Icon === 'string' ? (
                <Image className='max-w-6 max-h-6' width={24} height={24} src={Icon} alt='nav icon' aria-label='icon' />
              ) : (
                <Icon className={cls('size-6 text-icon', iconClassName)} alt='nav icon' />
              )
            ) : null}
            <div className={cls('hidden widescreen:block text-text', labelClassName)} aria-label='text'>
              {children}
            </div>
            {badge ?? null}
          </div>
        </div>
      </Link>
    )
  },
)

IconLink.displayName = 'IconLink'

export default IconLink
