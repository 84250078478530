'use client'

import useTheme from '@/hooks/useTheme'
import { useEffect } from 'react'

export interface ThemeToggleProps {
  className?: string
}

import { Switch, Thumb } from '@radix-ui/react-switch'
// import { Switch } from '@lemonsqueezy/wedges'
import SunIcon from '@haiper/icons-svg/icons/outline/sun.svg'
import MoonIcon from '@haiper/icons-svg/icons/outline/moon.svg'
import { cls } from '@/utils'
import { NO_OUTLINE_STYLE } from '@/constants'

export interface DarkModeSwitchProps {
  className?: string
}

function DarkModeSwitch({ className }: DarkModeSwitchProps) {
  const { theme, setTheme } = useTheme()

  const toggleDarkMode = () => {
    const newTheme = theme === 'dark' ? 'light' : 'dark'
    setTheme(newTheme)
  }

  const isDark = theme === 'dark'

  useEffect(() => {
    const htmlDom = document.querySelector('html')
    if (theme) {
      document.cookie = `theme=${theme};path=/;`
      htmlDom?.setAttribute('data-theme', theme)
      // className
      if (isDark) {
        htmlDom?.classList.remove('light')
        htmlDom?.classList.add('dark')
      } else {
        htmlDom?.classList.remove('dark')
        htmlDom?.classList.add('light')
      }
    } else {
      setTheme(
        window.matchMedia('(prefers-color-scheme: dark)').matches
          ? 'dark'
          : 'light',
      )
    }
  }, [theme, setTheme, isDark])

  const iconClassName = 'text-icon-interactive size-4'
  const Icon = isDark ? MoonIcon : SunIcon

  return (
    <Switch
      checked={isDark}
      className={cls(NO_OUTLINE_STYLE, className)}
      onCheckedChange={toggleDarkMode}
    >
      <Thumb>
        <div
          className={cls(
            'flex items-center justify-center text-icon w-11 h-6 rounded-full bg-icon-disable p-0.5',
            isDark ? 'justify-end' : 'justify-start',
          )}
        >
          <div className='size-5 flex items-center justify-center bg-surface rounded-full'>
            <Icon className={iconClassName} />
          </div>
        </div>
      </Thumb>
    </Switch>
  )
}

export default DarkModeSwitch
