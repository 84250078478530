import { useMemo } from 'react'
import { useCachedVisibleMarketingEvents } from './useVisibleMarketingEvents'
import dayjs from 'dayjs'
import useAlarms from './useAlarms'

/**
 * Get ongoing events
 * @param maxDurationSinceStart max duration since event start time, unit: ms
 * @returns
 */
export default function useOngoingEvents(maxDurationSinceStart: number = 0) {
  const { data: events, isValidating } = useCachedVisibleMarketingEvents()
  const alarmStops = useMemo(() => {
    return events?.map((event) => event.end_time) ?? []
  }, [events])

  const alarms = useAlarms(alarmStops)

  // id str, used to check if event ongoing state has changed. (ie, started or ended)
  const ongoingEventIds: string = useMemo(() => {
    const currentTime = dayjs()
    return events
      ?.filter((event) => {
        const startTime = dayjs(event.start_time)
        const endTime = dayjs(event.end_time)
        const maxEndTime = maxDurationSinceStart ? dayjs(event.start_time).add(maxDurationSinceStart, 'ms') : endTime

        return (
          event.status === 'ongoing' &&
          currentTime.isBefore(maxEndTime) &&
          currentTime.isBefore(endTime) &&
          currentTime.isAfter(startTime)
        )
      })
      .map((event) => event.event_id)
      .join(',')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events, maxDurationSinceStart, alarms])

  const ongoingEvents = useMemo(() => {
    const idArray = ongoingEventIds.split(',')
    return events?.filter((event) => {
      return idArray?.includes(event.event_id)
    })
  }, [events, ongoingEventIds])

  return {
    data: ongoingEvents,
    loading: isValidating,
    isValidating: isValidating,
  }
}
