'use client'

import { cls } from '@/utils'
import IconLink from '@/components/icon-link'
import { ButtonMore } from '../button-more'
import IconDots from '@haiper/icons-svg/icons/outline/dot-hor.svg'
import ThemeToggle from '@/components/theme-toggle'
import Button from '@/components/button'
import LoginSolid from '@haiper/icons-svg/icons/solid/login.svg'
import Link from '@/components/link'
import { NO_OUTLINE_STYLE } from '@/constants'
import useNavLinks from '@/hooks/useNavLinks'
import { useAtomValue } from 'jotai'
import { auth0SignInAtom } from '@/atoms'
import useAuth0Auth from '@/hooks/useAuth0Auth'
import useFooterLinks from '@/hooks/useFooterLinks'

const MoreOutline = IconDots
interface NavProps {
  className?: string
  activeKey?: string
}

export function FooterNav({ className, activeKey }: NavProps) {
  const isLogin = useAtomValue(auth0SignInAtom)
  const { login } = useAuth0Auth()
  const onLoginClick = () => login()

  const { data: navLinks, loading } = useNavLinks()
  const { data: footerLinks } = useFooterLinks()

  const renderFooter = () => {
    return loading ? null : isLogin ? (
      <div className='flex-1 flex px-5 py-3 justify-between items-center'>
        {navLinks.map(({ id, href, icon, activeIcon, label, key, onClick, className, iconClassName }) => (
          <div key={key} className={cls('flex items-center justify-center flex-1')}>
            <IconLink
              key={key}
              id={id}
              href={href || ''}
              icon={key === activeKey ? activeIcon : icon}
              iconClassName={iconClassName}
              className={cls('size-11', className)}
              active={key === activeKey}
              title={label}
              onClick={onClick}
            >
              {label}
            </IconLink>
          </div>
        ))}
        <div className={cls('h-full flex items-center justify-center flex-1')}>
          <ButtonMore withFooterLinks>
            <IconLink
              icon={MoreOutline}
              className={cls('flex appearance-none md:flex-1 justify-center aspect-square h-full md:h-auto')}
              href='#'
            >
              More
            </IconLink>
          </ButtonMore>
        </div>

        <div className='flex-col gap-2 hidden' aria-label='footer'>
          {footerLinks.map(({ href, icon, activeIcon, label, key, target }) => (
            <IconLink
              key={key}
              href={href}
              icon={key === activeKey ? activeIcon : icon}
              target={target}
              title={label}
              active={key === activeKey}
            >
              {label}
            </IconLink>
          ))}
          <div className='flex justify-center widescreen:justify-start widescreen:px-3 my-5'>
            <ThemeToggle />
          </div>
        </div>
      </div>
    ) : (
      <Link
        className={cls(
          'px-4 py-3 flex items-center justify-center size-full no-underline active:no-underline focus:ring-0 focus-visible:ring-0',
          NO_OUTLINE_STYLE,
        )}
        href=''
        onClick={onLoginClick}
      >
        <Button variant='primary' className='w-full rounded-lg'>
          <div className='flex items-center justify-center text-text-on-color gap-1'>
            <LoginSolid className='text-icon-on-color' />
            <span className='px-1 text-text-on-color'>Login to create</span>
          </div>
        </Button>
      </Link>
    )
  }

  return (
    <nav
      className={cls(
        'fixed inset-x-0 bottom-0 flex w-full shrink-0 overflow-y-auto no-scrollbar border-t md:border-r-2 border-solid border-border h-16 z-50 bg-surface md:hidden',
        className, // external className
      )}
      aria-label='footer nav'
      data-testid='sidebar'
    >
      {renderFooter()}
    </nav>
  )
}
