import(/* webpackMode: "eager" */ "/vercel/path0/app/globals.css");
import(/* webpackMode: "eager" */ "/vercel/path0/components/broadcasts/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/dialog/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/runtime/csr.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/side-bar/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/toaster/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@haiper/icons-svg/icons/band/instagram.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@haiper/icons-svg/icons/band/tiktok.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@haiper/icons-svg/icons/band/x.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@haiper/icons-svg/icons/band/youtube.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@haiper/icons-svg/icons/outline/ai-text.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@haiper/icons-svg/icons/outline/brush.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@haiper/icons-svg/icons/outline/clock-add.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@haiper/icons-svg/icons/outline/features.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@haiper/icons-svg/icons/outline/globus.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@haiper/icons-svg/icons/outline/image-square-2-mountains.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/ga.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/gtm.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next-runtime-env/build/provider/env-provider.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next-runtime-env/build/provider/use-env-context.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/logo-animation.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/xiaohongshu.svg")