import { Notification, Pagination, PaginationQuery } from '@/types'
import { rest } from '@/utils'

export const getNotifications = async (params: PaginationQuery<{}>): Promise<Pagination<Notification>> => {
  const res = await rest.get('/v1/user/notifications', { params })
  return res.data
}

export const getUnreadNotifications = async (params: PaginationQuery<{}>): Promise<Pagination<Notification>> => {
  const res = await rest.get('/v1/user/notifications', { params: { ...params, is_read: false } })
  return res.data
}

export const readNotification = async (id: string): Promise<void> => {
  await rest.put(`/v1/user/notifications/${id}/read`)
}

export const deleteNotification = async (id: string): Promise<void> => {
  await rest.delete(`/v1/user/notifications/${id}`)
}
