import { useToast } from '@/components/ui/use-toast'
import { useEffect } from 'react'
import { Alert } from '@lemonsqueezy/wedges'
import { cls } from '@/utils'
import useGlobalPadding from '@/hooks/useGlobalPadding'

const styleMap: Record<string, string> = {
  success:
    'text-text-success bg-surface-success dark:text-text-success dark:bg-surface-success',
  warning:
    'text-text-caution bg-surface-caution dark:text-text-caution dark:bg-surface-caution',
  error:
    'text-text-critical bg-surface-critical dark:text-text-critical dark:bg-surface-critical',
}

export function Toaster() {
  const { toasts } = useToast()

  // show only first toast
  const firstOpenToast = toasts.find((toast) => toast.open)
  const { absoluteClassName } = useGlobalPadding()

  useEffect(() => {
    if (!firstOpenToast) {
      return
    }

    const duration = firstOpenToast.duration ?? 3000
    setTimeout(() => {
      firstOpenToast.onOpenChange?.(false)
    }, duration)
  }, [firstOpenToast])

  if (!firstOpenToast) {
    return null
  }

  const color = firstOpenToast.color ?? 'warning'

  return (
    <div
      className={cls(
        'fixed z-70 top-6 w-full flex justify-center',
        absoluteClassName,
      )}
    >
      <Alert
        className={cls(
          styleMap[color],
          'absolute z-70 top-2 w-[560px] max-w-[90vw] text-body-md leading-6 mx-auto',
        )}
        color={color as any}
        title={firstOpenToast.title}
        content={firstOpenToast.content}
      />
    </div>
  )
}
