import IconNews from '@haiper/icons-svg/icons/outline/Newspaper.svg'
import IconBadgeVerifiedOutline from '@haiper/icons-svg/icons/outline/badge-verified.svg'
import IconBadgeVerifiedSolid from '@haiper/icons-svg/icons/solid/badge-verified.svg'
import IconCodeOutline from '@haiper/icons-svg/icons/outline/code.svg'
import IconCodeSolid from '@haiper/icons-svg/icons/solid/code.svg'
import BellOutline from '@haiper/icons-svg/icons/outline/bell.svg'
import BellSolid from '@haiper/icons-svg/icons/solid/bell.svg'
import AlbumsOutline from '@haiper/icons-svg/icons/outline/albums.svg'
import AlbumsSolid from '@haiper/icons-svg/icons/solid/albums.svg'
import CupChampionWinOutline from '@haiper/icons-svg/icons/outline/cup-champion-win.svg'
import CupChampionWinSolid from '@haiper/icons-svg/icons/solid/cup-champion-win.svg'

import { useBreakpoint } from './useBreakPoint'
import { NavLink } from '@/types'
import { useMemo } from 'react'
import { useUnreadNotificationsCount } from './useNotifications'
import { cls } from '@/utils'
import { usePathname } from 'next/navigation'

const useFooterLinks = () => {
  const { isBelowMd } = useBreakpoint('md')
  const pathname = usePathname()

  const unreadNotificationsCount = useUnreadNotificationsCount()
  const notificationLinks: NavLink[] = useMemo(() => {
    return [
      {
        href: '/notifications',
        key: 'notifications',
        icon: BellOutline,
        activeIcon: BellSolid,
        label: 'Notifications',
        badge:
          unreadNotificationsCount > 0 && pathname !== '/notifications' ? (
            <div className='absolute inset-y-0 right-0 flex items-center'>
              <span
                className={cls(
                  'w-6 h-5 rounded-sm bg-surface-critical-strong text-text-on-color shrink-0 flex items-center justify-center text-body-sm font-medium',
                )}
              >
                {unreadNotificationsCount > 99 ? '99+' : unreadNotificationsCount}
              </span>
            </div>
          ) : undefined,
      },
    ]
  }, [unreadNotificationsCount, pathname])

  const result = useMemo(() => {
    return {
      data: [
        ...(!isBelowMd
          ? []
          : [
              {
                href: '/templates',
                key: 'templates-home',
                icon: AlbumsOutline,
                activeIcon: AlbumsSolid,
                label: 'Templates',
              },
            ]),
        ...(!isBelowMd
          ? []
          : [
              {
                href: '/events',
                key: 'events',
                icon: CupChampionWinOutline,
                activeIcon: CupChampionWinSolid,
                label: 'Events',
              },
            ]),
        ...(isBelowMd ? notificationLinks : []),
        ...(isBelowMd
          ? [
              {
                href: '/membership',
                key: 'membership',
                icon: IconBadgeVerifiedOutline,
                activeIcon: IconBadgeVerifiedSolid,
                label: 'Membership',
              },
            ]
          : []),
        {
          href: '/haiper-api',
          key: 'api',
          icon: IconCodeOutline,
          activeIcon: IconCodeSolid,
          label: 'API',
        },
        {
          href: '/blog',
          key: 'blog',
          icon: IconNews,
          label: 'Blog',
          target: '_blank',
        },
      ] as NavLink[],
      loading: false,
    }
  }, [isBelowMd, notificationLinks])

  return result
}

export default useFooterLinks
