import { useMemo } from 'react'
import useMarketingEvents, { useCachedMarketingEvents } from './useMarketingEvents'

export default function useVisibleMarketingEvents() {
  const { data: marketingEventsRes, ...others } = useMarketingEvents()
  const marketingEvents = useMemo(
    () => (marketingEventsRes?.records ?? []).filter((item) => item.visible),
    [marketingEventsRes],
  )
  return {
    data: marketingEvents,
    ...others,
  }
}

export const useCachedVisibleMarketingEvents = () => {
  const { data: marketingEventsRes, ...others } = useCachedMarketingEvents()
  const marketingEvents = useMemo(
    () => (marketingEventsRes?.records ?? []).filter((item) => item.visible),
    [marketingEventsRes],
  )
  return {
    data: marketingEvents,
    ...others,
  }
}
